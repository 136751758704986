// General
import React, { useMemo } from 'react';
// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Ui-kit
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import Spinner from 'ui-kit/spinner/spinner';

// Components
import EmptyCart from 'components/medicine-cabinet-cart/empty-cart/empty-cart.component';
import HealthProfile from 'components/medicine-cabinet-cart/health-profile/health-profile.component';
import PaymentMethod from 'components/medicine-cabinet-cart/payment-method/payment-method.component';
import PrescriptionInformation from 'components/medicine-cabinet-cart/prescription-information/prescription-information.component';
import ShippingAddress from 'components/medicine-cabinet-cart/shipping-address/shipping-address.component';
import ShippingMethod from 'components/medicine-cabinet-cart/shipping-method/shipping-method.component';
import SidebarColumn from 'components/sidebar-column/sidebar-column.component';

import { accountIsLoadingPaymentMethodsSelector } from 'state/account/account.selectors';
// States
import {
    cartIsBusySelector,
    isLoadingAddressShippingCartSelector,
    isLoadingPaymentsCartSelector
} from 'state/cart/cart.selectors';
import { healthConditionsByPatientNumberSelector } from 'state/medical-conditions/medical-conditions.selector';
import { medicineCabinetIsBusySelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

import { BREAKPOINTS } from 'const/breakpoints';

import { ApiStatus } from 'enums/api-status';

import useCart from 'hooks/useCart';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { CartTotal } from './cart-total/total-cart.component';
// Styles
import './medicine-cabinet-cart.style.scss';

// Main component
const MedicineCabinetCart: React.FC = () => {
    // Hooks
    const { t } = useTranslation();
    const { cartData } = useCart();

    // Selectors
    const cartIsBusy = useSelector(cartIsBusySelector);
    const isLoadingPayments = useSelector(isLoadingPaymentsCartSelector);
    const isLoadingAddressShipping = useSelector(isLoadingAddressShippingCartSelector);
    const isLoadingCreditCardsAccount = useSelector(accountIsLoadingPaymentMethodsSelector);
    const prescriptionsIsBusySelector = useSelector(medicineCabinetIsBusySelector);
    const healthConditionsByPatientNumber = useSelector(healthConditionsByPatientNumberSelector);

    const isLoadingHealthConditions = Object.values(healthConditionsByPatientNumber).some(
        (healthConditions) =>
            healthConditions.allergiesApiStatus === ApiStatus.LOADING ||
            healthConditions.medicalConditionsApiStatus === ApiStatus.LOADING
    );
    const isCartLoading = useMemo(
        () =>
            isLoadingAddressShipping ||
            isLoadingHealthConditions ||
            prescriptionsIsBusySelector ||
            isLoadingPayments ||
            isLoadingCreditCardsAccount ||
            cartIsBusy,
        [
            isLoadingAddressShipping,
            isLoadingHealthConditions,
            prescriptionsIsBusySelector,
            isLoadingPayments,
            isLoadingCreditCardsAccount,
            cartIsBusy
        ]
    );

    const { width } = useWindowDimensions();

    return (
        <SidebarColumn
            className={`medicine-cabinet-v2-cart ${width <= BREAKPOINTS.sm ? 'medicine-cabinet-v2-cart--mobile' : ''}`}
        >
            {isCartLoading && !!cartData && cartData?.cartItems > 0 && (
                <div className="medicine-cabinet-v2-cart__loading__blocker">
                    <Spinner isVisible fullOverlay={false} t={t} color="silver" focusOnShow />
                </div>
            )}

            <div className="medicine-cabinet-v2-cart__header">
                <CartIcon className={'header-icon-container'} />
                <h3>{t('components.medicineCabinetCart.title')}</h3>
            </div>

            {cartData?.cartItems === 0 && isCartLoading ? (
                <div className="medicine-cabinet-v2-cart__loading">
                    <Spinner isVisible fullOverlay={false} t={t} color="silver" />
                </div>
            ) : (
                <>
                    {cartData && cartData?.cartItems > 0 ? (
                        <>
                            <ShippingAddress />
                            <ShippingMethod />
                            <PaymentMethod buttonVariant="text-blue" showSelectCardRadioInput showSetDefaultLink />
                            <HealthProfile />
                            <PrescriptionInformation />
                            <CartTotal isUnknownPrice={false} />
                        </>
                    ) : (
                        <EmptyCart
                            title={t('components.medicineCabinetCart.empty.title')}
                            body={t('components.medicineCabinetCart.empty.body')}
                        />
                    )}
                </>
            )}
        </SidebarColumn>
    );
};

export default MedicineCabinetCart;
