import LoadingPrescriptionsImg from 'assets/images/loading-prescriptions.svg';
import PrescriptionsNotFound from 'assets/images/no-prescriptions-found.svg';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'ui-kit-v2/button/button';
import { SpinnerV2 } from 'ui-kit-v2/spinner/spinner';

import { LoadingPrescriptionsProps } from './loading-prescriptions.props';
import './loading-prescriptions.styles.scss';

export const LoadingPrescriptions = ({
    isVisible = true,
    state = 'loading',
    onButtonClick
}: LoadingPrescriptionsProps) => {
    const { t } = useTranslation();

    if (isVisible) {
        switch (state) {
            case 'loading':
                return (
                    <div className="loading-prescriptions-container loading-prescriptions-container--loading">
                        <img src={LoadingPrescriptionsImg} alt={t('pages.medicineCabinet.loading')} />
                        <div className="loading-prescriptions-container__information">
                            <div className="loading-prescriptions-container__spinner">
                                <SpinnerV2 color="cathams-blue" size="sm" />
                            </div>
                            <h2>{t('pages.medicineCabinet.loading')}</h2>
                            <p>{t('pages.medicineCabinet.loadingInformation')}</p>
                        </div>
                    </div>
                );

            case 'error':
                return (
                    <div className="loading-prescriptions-container loading-prescriptions-container--error">
                        <img src={PrescriptionsNotFound} alt={t('pages.medicineCabinet.errorLoadingPrescriptions')} />
                        <div className="loading-prescriptions-container__information">
                            <h2>{t('pages.medicineCabinet.errorLoadingPrescriptions')}</h2>
                            <p>{t('pages.medicineCabinet.errorLoadingPrescriptionsInformation')}</p>
                        </div>
                        <ButtonComponent
                            variant="outline"
                            label={t('pages.medicineCabinet.tryAgain')}
                            onClick={() => onButtonClick?.()}
                        />
                    </div>
                );

            case 'empty':
                return (
                    <div className="loading-prescriptions-container loading-prescriptions-container--empty">
                        <img src={PrescriptionsNotFound} alt={t('pages.medicineCabinet.emptyCabinetTitle')} />
                        <div className="loading-prescriptions-container__information">
                            <h2>{t('pages.medicineCabinet.emptyCabinetTitle')}</h2>
                            <p>{t('pages.medicineCabinet.emptyCabinetDescription')}</p>
                        </div>
                        <ButtonComponent
                            variant="outline"
                            label={t('pages.medicineCabinet.transferRx')}
                            onClick={() => onButtonClick?.()}
                        />
                    </div>
                );

            default:
                return null;
        }
    }

    return null;
};
